<template>
  <div class="Main">
    <img src="../assets/imgs/4.png" style="width:100%">
    <img src="../assets/imgs/10.png" style="width:100%">
    <img src="../assets/imgs/11.png" style="width:100%">
    <img src="../assets/imgs/12.png" style="width:100%">
    <img src="../assets/imgs/13.png" style="width:100%">
    <img src="../assets/imgs/14.png" style="width:100%">
    <div style="text-align:center;padding:20px;font-size:20px;">
        &copy;武步青云 2022 &nbsp;&nbsp;&nbsp;&nbsp;联系电话：400-8200-082 &nbsp;&nbsp;&nbsp;&nbsp;备案信息：<a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2021028131号-1</a>  
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',

  data(){
    return {

    }
  },
  mounted(){

  },
  beforeUnmount(){

  },
  methods:{

  }
}
</script>

<style scoped>
.Main{
  width:1400px;
  margin:10px auto;
}
.Main img{
  margin-top:8px;
}
</style>
